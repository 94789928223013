.section-ten {
	background-color: #1c1c1c;
	padding: 80px 0;

	.image-holder {
		text-align: center;
		margin-bottom: 50px;
	}

	.text-holder {
		text-align: center;
		margin-bottom: 50px;

		.text {
			font-size: 28px;
			font-weight: 300;
			color: #fff;
			max-width: 770 px;
			margin: 0 auto;
			width: 100%;
			line-height: 120%;
			@media (max-width: 480px) {
				font-size: 18px;
				text-align: left;
			}
		}
	}

	.subscribe-holder {
		max-width: 380px;
		margin: 0 auto;

		.input-group {
			border-bottom: 1px solid #6b6b6b;
			padding-bottom: 5px;

			input {
				border-left: 0;
				border-radius: 0;
				background-color: transparent;
				padding-left: 10px;
				border: 0;
				padding-bottom: 0;
				color: #fff;

				&::placeholder {
					color: #6b6b6b;
				}

				&:focus {
					color: #fff;
					border: 0;
					background: transparent;
					box-shadow: none;
				}
			}

			.input-group-append {
				border-radius: 0;
				border: 0;
				border-right: 0;
				padding: 6px 17px;

				img {
					max-height: 16px;
				}
			}

			.count-me-in {
				border-radius: 0;
				border: 0;
				border-right: 0;
				background: transparent;
				color: #fff;

				img {
					max-width: 30px;
					position: relative;
					top: -3px;
					right: 17px;
				}
			}
		}
	}
}

.post-content .section-ten {
	background-color: #fff !important;
	padding: 0px 0 30px;
}

.inputHubSpot {
	position: relative;
	width: 20rem;
	margin: 0 auto;

	overflow: hidden;
}

.inputHubSpotText {
	color: #ffff;
	font-size: 1.3rem;
	font-weight: 300;
	text-align: center;
}

.inputHubSpotLoading {
	position: absolute;
	width: 100%;
	height: 3rem;
	background-color: #1c1c1c;

	top: 2.5rem;
	left: 0;
}

.inputHubSpotLoadingActive {
	top: 0;
	transition: all 0.2s;
}

@media (max-width: 576px) {
	.inputHubSpot {
		width: 15rem;
		margin: 0 auto;
	}
}

@media (max-width: 480px) {
	.section-ten {
		padding: 40px 0;
	}

	.inputHubSpot {
		width: 20rem;
	}
}

.nubSpotForm {
	display: none;
}
