@keyframes appear {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}
}

.statesWindowsConteiner {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	text-align: center;
	z-index: 9999;
	transition: all 0.5s;
	animation: appear 350ms ease-in 1;

	backdrop-filter: saturate(90%) blur(3px);
}

.statesWindowsModal {
	position: absolute;
	display: flex;
	flex-direction: column;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: all 0.5s;
	width: 20rem;
	overflow: auto;

	background-color: #fff;
	border-radius: 10px;
	padding: 3rem;
	width: max-content;
}

.boxmodal {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.closemodal {
	height: 3rem;
	width: 3rem;
	border: 3px solid #b3b3b3;
	border-radius: 50%;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.5s;

	&:hover {
		border: 3px solid #feb53d;

		.closeBtn {
			color: #feb53d;
		}
	}
}

.closeBtn {
	font-size: 1.5rem;
	color: #b3b3b3;
	cursor: pointer;
	z-index: 250;
	transition: all 0.5s;
}

.titlemodal {
	font-size: 1.5rem;
	margin: 0.5rem;
}

@media (max-width: 650px) {
	.statesWindowsModal {
		width: 80%;
	}
}

.reviemsLink {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
